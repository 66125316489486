.dropzone{
    background: #212121!important;
}
.dropzone-text{
    color:white;
}
.MuiDropzoneArea-root{
    border-color: rgb(255 255 255 / 32%)!important;
}
.MuiDropzoneArea-icon{
    color: white!important;
    width: 124px!important;
    height: 119px!important;
}