.chatMic{
    width: 52px;
    height: 42px;
    cursor: pointer;
    text-align: center;
    line-height: 52px;
    border-radius: 50%;
    margin-right: 10px;
    -webkit-writing-mode: vertical-lr;
    transition : all 0.3s ease;
}

.disableChat{
    background: #121212;
    transition : all 0.3s ease;
}

.enableChat{
    background: #388e3c;
    transition : all 0.3s ease;
}