:root {
    color-scheme: dark;
}
::-moz-selection {
    background: #296b2c;
}
::-webkit-selection {
    background: #296b2c;
}
::selection {
    background: #296b2c ;
}
/* ::-webkit-scrollbar {
    width: 12px; 
}

::-webkit-scrollbar-thumb {
    background: #296b2c; 
}

::-webkit-scrollbar-track {
    background: #000; 
} */

* {
    scrollbar-color: #296b2c #000;
}

::-webkit-scrollbar-thumb:window-inactive {
    background: #296b2c; 
}

b{
    color: #3ca741;
}