.item-card{
    padding: 10px;
    margin-top: 10px;
}

.item-input{
    width: calc(100% - 50px);
}

.drag-icon{
    float: right;
}

.close-title-btn{
    position: relative;
    display: contents;
   /* color: #f44336;*/
}