.image-prompt-input{
    border-radius: 10px;
    padding: 12px;
    font-size: 20px;
    height: 30px;
    width: calc(100% - 25px);
    border-style: dashed;
    border-width: 2px;
    resize: vertical;
    min-height: 80px;

}
.style-preview{
    border-radius: 8px;
    /*width:96px*/
    width: 100%;
    max-width: 152px;
}