.msg-avatar {
  width: 48px;
  height: 48px;
}

.msg-userRow {
  text-align: left;
  border-top-left-radius: 12.5px;
  border-bottom-left-radius: 12.5px;
  background-color: #388e3c;
  color: #ffffff;
}

.msg-iaRow {
  text-align: right;
  border-top-right-radius: 12.5px;
  border-bottom-right-radius: 12.5px;
  background-color: #f0f0f0;
  color:black;
}

.msg-messageRow {
  border: 1px solid #0000004a;
  padding: 14px;
  margin-bottom: 20px;
}

.msg-msg {
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 4px;
  display: inline-block;
  word-break: break-word;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
}